@font-face {
	font-family:"BMWGroupSerif-Light";
	src:url("../fonts/BMWGroupTNSemiSfCnProTT-Light.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Regular";
	src:url("../fonts/BMWGroupTNProTT-Regular.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Bold";
	src:url("../fonts/BMWGroupTNProTT-Bold.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Light";
	src:url("../fonts/BMWGroupTNProTT-Light.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNCondensedProTT-Regular";
	src:url("../fonts/BMWGroupTNCondensedProTT-Regular.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNCondensedProTT-Bold";
	src:url("../fonts/BMWGroupTNCondensedProTT-Bold.woff") format("woff")
}