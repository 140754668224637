@tailwind base;
@tailwind components;
@tailwind utilities;

@import "_fonts.css";

body {
	line-height: 1.375rem;
	@apply text-default text-base font-regular;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	@apply font-condensed-bold uppercase;
}

h1, .h1 {
	@apply text-h1;
}

h2, .h2 {
	@apply text-h2;
}

h3, .h3 {
	@apply text-h3;
}

h4, .h4 {
	@apply text-h4;
}

p a {
	@apply font-regular font-bold underline transition hover:text-grey;
}

strong {
	@apply font-condensed-bold;
}

.container {
	max-width: 1444px;
}

.brand {
	height: 38px;
}

.play-video:after{
	@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
	content: '';
	width: 80px;
	height: 50px;
	background: url('../images/button_play.svg');
	background-size: contain;
}

.play-video:before {
	@apply absolute inset-0 bg-black opacity-0 transition-all duration-150;
	content: '';
}

.play-video:hover:before{
	@apply opacity-30;
}

.button {
	@apply h-[56px] pl-6 pr-10 text-white inline-flex items-center relative font-condensed-bold max-w-[378px] transition-all;
}

.button:after {
	@apply absolute inset-0;
	content: '';
	background-image: url('../images/button_arrow.svg');
	background-position: right 0.8rem center;
	background-repeat: no-repeat;
}